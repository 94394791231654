@import "colors.ncs1.css";

.App {
position: relative;
z-index: 0;

}

.Wrapper {
  


animation-name: div-bg-fade2;
animation-duration: .5s;

min-height: 100vh;
min-width: 96vw;
  }
  

.Main {
  position: relative;
z-index: 0;


min-width: 96vw;
 
  }


  
  .Body {
    position: relative;
  z-index: 0;
min-width: 96vw;
  



   }
  
   
  .fadeshow2 { 
      display:none; 
 
      
    }

.navBarTitle {
    font-size: calc(2.5vw + 24px);  
 
}



.navBarBox {
    
  top :0;
  text-align: center;
  font-weight: bold;
  position: relative;
  z-index: 20;
  
  align-content: 'center';
  align-items: 'center';
  height: auto;
 }



.navBarLink3 {
  
  
  font-size: calc(4px +  1.0vw);
  min-width: 10.15vw;
  max-width: 10.15vw;
  border-radius: 30px;
  border-color: black;
  border-style: solid;
  border-width: 1px;
  padding-left: 0vw;
  padding-right:0vw;
}

.navBarLink3blue {
  font-size: calc(4px +  1.0vw);
  min-width: 10.15vw;
  max-width: 10.15vw;
  padding-left:0vw;
  padding-right:0vw;
  border-radius: 30px;
  border-color: #163f99;
  border-style: solid;
  border-width: 1px;
}



.navBarLink3orange {
  font-size: calc(4px +  1.0vw);
  min-width: 10.15vw;
  max-width: 10.15vw;
  padding-left:0vw;
  padding-right:0vw;
  border-radius: 30px;
  border-color: #c26e20;
  border-style: solid;
  border-width: 1px;
}



.gameLink1 {
  
  align-content: center;
  font-size: calc(10px + 1vw);
   min-width: calc(100px + 18vw);
  min-height: calc(30px + 2vw);
  border-radius: 5px;
  font-weight: bold;
  border-style: solid;
  border-width: 1px;
 
  z-index: 10;
}


.pageContent {
  text-align: center;
  
}


.pageContent2 {
  font-size: calc(16px + .5vw);


}



.pageContentSmall {
 text-align: center;
 font-size: calc(12px + .5vw);

 
}


.pageContentFaq {
  text-align: center;
  font-size: calc(12px + .5vw);

  
 }
 
.pageContentHedder {
  text-align: center;

}

.pageContentP {
  display: flex;
  position: relative;
  text-align: center;
  z-index: 1; 



}




.pageContentHedderP {
 

  font-size: calc(3.45vw + 16px) ;
  padding-top: 3vh  ;
padding-bottom: 3vh ;

}



.pageContentH {
  display: flex;
  position: relative;
  text-align: center;
  z-index: -1; 
min-height: calc(10vh);
padding-top: calc(4vh - 22px) ;
padding-bottom: calc(4vh - 22px) ;
}



.pageContentHedderH {
 

  font-size: calc(3.45vw + 16px) ;
  
  margin-top: -.8vw;
  
  

}


.mid-bar {
margin-top: -.55vw;
  font-Size: calc(1.95vw + 7px);
padding-bottom: .55vw;

}


.helpContent {
  text-align: center;
}


.helpContentSmall2 {
  text-align: left;
  font-size: 1.3vw;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}

.helpContentTitle {
  text-align: center;
  font-size: 2vw;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}

.helpContentHedder {
  text-align: left;
  font-size: 2vw;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}


.tosContentSmall2 {
  text-align: left;
  font-size: 1.3vw;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 0%;
}

.tosContentTitle {
  text-align: center;
  font-size: 2vw;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}

.Content404 {
  text-align: center;
  font-size: 5vh;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0%;
}



.menuIconRight {
  height:6.5vw;
}

.menuIconLeft {
  height:5.7vw;
}


@keyframes div-bg-fade2 {
  0%   {opacity: 0;}
  10%  {opacity: .1;}
  20%  {opacity: .2;}
  30%  {opacity: .3;}
  40%  {opacity: .4;}
  50% {opacity: .5;}
  60% {opacity: .6;}
  70% {opacity: .7;}
  80% {opacity: .8;}
  90% {opacity: .9;}
  100% {opacity: 1;}
}



.zoom {


  transition: transform .3s ease; /* Animation */
 
  

}

.zoom:hover {
  transform: scale(1.05); 

}


.cTabletLong { 
  display: none; 
  visibility: hidden;
  
}


.cMobileLong { 
  display: none; 
  visibility: hidden;
  
}

.cMobileLong2 { 
  display: none; 
  visibility: hidden;
  
}

.cLong { 
  display: none; 
  visibility: hidden;
  
}

.cWide { 
  display: none; 
  visibility: hidden;
  
}


.cTabletLong2 { 
  display: none; 
  visibility: hidden;
  
}


.cLong2 { 
  display: none; 
  visibility: hidden;
  
}

.cWide2 { 
  display: none; 
  visibility: hidden;
  
}

.cWideLong { 
  display: none; 
  visibility: hidden;
  
}

.cWideLong2 { 
  display: none; 
  visibility: hidden;
  
}

.cWide2Long2 { 
  display: none; 
  visibility: hidden;
  
}

.cWide2Long { 
  display: none; 
  visibility: hidden;
  
}


.cMed { 
  display: flex; 
  visibility: visible;
  }

  .cPC { 
    display: none; 
    visibility: hidden;
    
  }
.cMobile { 
  display: none; 
  visibility: hidden;
  
}


.navbarPC {
  visibility: visible;
  display: block;
}

.navbarMobile {
  visibility: hidden;
  display: none;
}




 .footText{
  font-size: 11px;
  padding-top: 4px;
  background-color:inherit;
  color: inherit;
  }


.live {
color: red;
}

.cType {
  display:none;
  visibility: hidden;
  font-size: 12px;
  background-color: cyan;
  color: black;
  position: absolute;
  left: 0px;
}






@media screen and (min-height: 1000px) and (min-width: 690px) and (max-width: 1199px ) {

  .cType { 
    background-color: purple;
    color: white;
  }

  .cTabletLong { 
    display: flex; 
    visibility: visible;
  }

  .cMed { 
    display: none; 
    visibility: hidden;
  }

 
  
  
@media screen and (min-height: 1300px)  {
  .cType {
    background-color: rgb(108, 121, 78);
    color: rgb(0, 0, 0);
  }

.cTabletLong2 { 
display: flex; 
visibility: visible;
}

.cTabletLong { 
display: none; 
visibility: hidden;
}




}

 
 }






@media screen and (max-width: 689px) {

.cType {
  background-color: rgb(173, 3, 3);
  color: white;
}


.navbarMobile {
visibility: visible;
display: block;
}

.navbarPC {
visibility: hidden;
display: none;
}

.pageContentH {

overflow: hidden;

}

.pageContentHedderH {

font-size: 5.7vw
}

.pageContentHedderP {

font-size: 5.7vw
}


.mid-bar {
font-size: 3.0vw ;


}

.navBarTitle {
font-size: 5.9vw;  

}


.cMobile { 
  display: flex; 
  visibility: visible;
}


.cMed { 
  display: none; 
  visibility: hidden;
}





.footText{

  font-size: 7px;
  background-color:inherit;
  color: inherit;
  }
  



  @media screen and (min-height: 1000px)  {
    .cType {
      background-color: rgb(219, 126, 126);
      color: white;
    }
  
.cMobileLong { 
  display: flex; 
  visibility: visible;
}

.cMobile { 
  display: none; 
  visibility: hidden;
}





@media screen and (min-height: 1300px)  {
  .cType {
    background-color: rgb(121, 78, 78);
    color: rgb(0, 0, 0);
  }

.cMobileLong2 { 
display: flex; 
visibility: visible;
}

.cMobileLong { 
display: none; 
visibility: hidden;
}




}






  }




}




@media screen and (min-width: 1200px) {

.cType {
  background-color: yellow;
  
}

.footText{


font-size: 14px;
}

.navbarMobile {
visibility: hidden;
display: none;
}
.navbarPC {
visibility: visible;
display: block;
}




.mid-bar {
  font-Size:  calc(1.7vw + 10px);


}

.pageContentHedderH {

  font-size: 4.8vw
}

.pageContentHedderP {

  font-size: 4.8vw
}


  .cPC { 
    display: flex; 
    visibility: visible;
  }

  .cMed { 
    display: none; 
    visibility: hidden;
  }
  
  .cMobile { 
    display: none; 
    visibility: hidden;
  }
  

  .navBarLink3 {


    font-size: 1.3vw;
    
  }

  .navBarLink3blue {
    font-size: 1.3vw;
   
  }
  
  
  
  .navBarLink3orange {
    font-size: 1.3vw;
    
  }
  
  .navBarTitle {
    font-size: 4.5vw;  
 
}



.navBarBox {
  height: auto;
 }





 @media screen and (min-height: 1000px)  and (max-width: 1949px ) {

  .cType {
    background-color: green;
    color: white;
  }

  .cLong { 
    display: flex; 
    visibility: visible;
  }

  .cPC { 
    display: none; 
    visibility: hidden;
  }



          



  @media screen and (min-height: 1300px)  {
    .cType {
      background-color: rgb(97, 121, 78);
      color: rgb(0, 0, 0);
    }
  
.cLong2 { 
  display: flex; 
  visibility: visible;
}

.cLong { 
  display: none; 
  visibility: hidden;
}





  }
  
 

 }



 @media screen and (min-width: 1950px) and (max-width: 2849px) {

  .cType {
    background-color: blue;
    color: white;
  }

  .cWide { 
    display: flex;              
    visibility: visible;
  }

  .cPC { 
    display: none; 
    visibility: hidden;
  }




.navBarTitle {

font-size: 88px;  
}



.navBarLink3 {


font-size: 25px;
min-width: 200px;

}

.navBarLink3blue {
font-size: 25px;
min-width: 200px;

}



.navBarLink3orange {
font-size: 25px;
min-width: 200px;

}

.pageContentHedderH {
text-align: center;  
font-size: 93px;


}

.pageContentHedderP {
text-align: center;  
font-size: 93px;


}


.mid-bar {
font-Size: 43px;
padding-bottom: 10px;

}




@media screen and (min-height: 1000px) {


.cType {
  background-color: rgb(87, 87, 255);
  color: rgb(0, 0, 0);
}

.cWideLong { 
  display: flex;              
  visibility: visible;
}

.cWide { 
  display: none; 
  visibility: hidden;
}







 @media screen and (min-height: 1300px)  {
  .cType {
    background-color: rgb(121, 78, 108);
    color: rgb(0, 0, 0);
  }

.cWideLong2 { 
display: flex; 
visibility: visible;
}

.cWideLong { 
display: none; 
visibility: hidden;
}




}



}


 
 }









 @media screen and (min-width: 2850px) {

  .cType {
    background-color: orange;
    color: white;
  }



  
  .cWide2 { 
    display: flex;              
    visibility: visible;
  }

  .cPC { 
    display: none; 
    visibility: hidden;
  }




.navBarTitle {

font-size: 88px;  
}





.navBarLink3 {


font-size: 25px;
min-width: 200px;

}

.navBarLink3blue {
font-size: 25px;
min-width: 200px;

}



.navBarLink3orange {
font-size: 25px;
min-width: 200px;

}

.pageContentHedderH {
text-align: center;  
font-size: 93px;
padding-top: -8px;
    

}

.pageContentHedderP {
  text-align: center;  
  font-size: 93px;


}



.mid-bar {
font-Size: 43px;
padding-bottom: 10px;

}



  @media screen and (min-height: 1000px) {
    

    .cType {
      background-color: rgb(54, 120, 196);
      color: white;
    }
  

  
    .cWide2Long { 
      display: flex;              
      visibility: visible;
    }
  
    .cWide2 { 
      display: none; 
      visibility: hidden;
    }
  



     @media screen and (min-height: 1300px)  {
      .cType {
        background-color: rgb(106, 121, 78);
        color: rgb(0, 0, 0);
      }
    
  .cWide2Long2 { 
    display: flex; 
    visibility: visible;
  }
  
  .cWide2Long { 
    display: none; 
    visibility: hidden;
  }
  

  
    }




  }





   
   }









  }


