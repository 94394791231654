.App {
  
  background-color: #000000
}

.Wrapper {
  background-color: #000000
  }
  
.Main {
  
    }



.Body {
  
}



.Mid-bar {
  
  color: #000000;
}



.navBarLink3 {
  color: black;
  background-color: #e7e7e7; 
}
.navBarLink3:hover {
  color:#000000;
  background-color: #c5c5c5; 
}


.navBarLink3blue {
  color: #ffffff;
  background-color: #2568FB; 
}

.navBarLink3blue:hover {
  color: #ffffff;
  background-color: #163f99; 
}

.navBarLink3orange {
  color: #ffffff;
  background-color: #FB8B24; 
  
}

.navBarLink3orange:hover {
  color: #ffffff;
  background-color: #c26e20; 
}

.navBarLink4 {
  color: #2568FB;
  background-color: #e7e7e7; 
}

.gameLink1 {
  color: black;
  background-color: #e7e7e7; 
}

.gameLink1:hover {
  color:#000000;
  background-color: #c5c5c5; 
}


.navBarLinkActive {
  
  color: #2568FB ; 
  
}

.navBarTitle {
  color:#000000;
  
}

 .navBarBox {
    background-color:  #e7e7e7;
  }


.pageContent {
  color: #000000;
  background-color: #e7e7e7;
}

.Content404 {
  color: #e7e7e7;
  
}

.pageContentSmall {
  color: #ffffff;
  
}


.pageContentFaq {
  color: #ffffff;
  
}

.pageContentSmallLeft {
  color: #ffffff;
  
}

.pageContentSmallLeft3 {
  color: #ffffff;
  
  
}

.pageContent2 {
  color:#FB8B24;
  
}

.pageContent3 {
  color:#FB8B24;
  background-color: black ;
}

.pageContentBold {
  color: #000000;
  background-color: #e7e7e7;
}

.pageContentA {
  color: #ffffff;
  
}

.pageContentH {
  color: #ffffff;
  
}

.pageContentP {
  color: #ffffff;
  
}


.pageContentMed2 {
  color: rgb(0, 0, 0);
}

.pageContentHedder {
  color: #ffffff    ;
}

.pageContentHedderH {
  color: #FB8B24    ;
}

.pageContentHedderP {
  color: #FB8B24    ;
}


.pageContentHedderLower {
  color: #ffffff ;
  
}

.pageContentHedderLowerH {
  color: #FB8B24 ;  
}


.pageContentHedder2 {
  color: #000000 ;
  border-color: #2568FB;
  background-color: #e7e7e7 ;
}



.helpContent {
  color: #e0e0e0;

}


.tosContentSmall2 {
  color: #e0e0e0;
}

.tosContentBold {
  color: #e7e7e7;
}

.tosContentTitle {
  color: #e7e7e7;
}



.helpContentHedder {
  color: #e7e7e7;
}

.helpContentTitle {
  color: #ffffff;
  
}

.helpContentSmall2 {
  color: #e0e0e0;
}











.Misc-button {
  background-color: #0F4C5C;
  color: white ;
}

.SO-button {
  background-color:  #9A031E;
  color: white;
}


.RB-button {
  background-color: #FB8B24;
  color: white;
}


.SP-button {
  background-color: #ce5a12;
  color: white;
}




